/* Estilos para pantallas grandes */
.mainContainer {
    position: sticky;
    z-index: 1000;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background-color: #6044f4;
    color: #fff;
    padding-bottom: 25px;
}

.mainContainer a{
    text-decoration: none;
    color: white;
  }

  .mainContainer a:hover{
    text-decoration: none;
    color: black;
  }
  

.rightContainerButtonsHolder {
    display: flex;
    align-items: flex-start;
}

.leftContainer{
    display: flex;
    align-items: center;
}

.leftContainer h1 {
    font-weight: bold;
    margin: 0;
    font-size: 32px;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    cursor: pointer;
}

.leftContainer h1:hover {
    text-decoration: none;
    transform: scale(1.05);
}

.leftContainer h1::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -5px;
    left: 0;
    
    transition: width 0.5s ease-in-out;
}

.leftContainer h1:hover::after {
    width: 0;
}

.leftContainer img {
    margin-right: 10px;
    max-width: 32px;
    height: auto;
    transition: max-width 0.3s ease-in-out;
    cursor: pointer;
}

.leftContainer img:hover {
    max-width: 32px;
}

.rightContainer p {
    margin: 0;
    font-size: 18px;
}

.rightContainer p:hover {
    cursor: pointer;
}

.rightContainer p::before {
    content: "\f16d";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 24px;
    margin-right: 5px;
}

.instagram-icon {
    margin-left: 20px;
    position: relative;
}

.instagram-icon a {
    display: inline-block;
    color: white;
}

.instagram-icon a::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.instagram-icon a:hover::before {
    opacity: 1;
}

.instagram-icon svg {
    vertical-align: middle;
}

.buttonHeader{
    background-color: #6044f4;
    color: white;
    font-size: 16px;
}

.buttonHeader:hover{
    background-color: #ffbc3c;
    color: black;
}

/* Estilos para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .mainContainer {
        flex-direction: column;
        padding: 10px;
    }

    .rightContainerButtonsHolder {
        display: flex;
        justify-content: flex-start; /* Alinea los botones hacia la izquierda */
        flex-wrap: wrap; /* Permite que los botones se envuelvan si no hay suficiente espacio */
        margin-top: 10px; /* Agrega un margen superior para separarlos del título */
        text-align: center; /* Centra los botones horizontalmente */
    }

    .rightContainerButtonsHolder button {
        font-size: 12px; /* Ajusta el tamaño de la fuente de los botones */
        padding: 5px 10px; /* Ajusta el padding de los botones según sea necesario */
        margin: 5px; /* Agrega un margen entre los botones */
        background-color: #6044f4; /* Color de fondo del botón */
        color: white; /* Color del texto del botón */
        border: none; /* Quita el borde del botón */
        cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
        width: 45%;
    }
    
    .rightContainerButtonsHolder button:hover {
        background-color: #ffbc3c; /* Cambia el color de fondo del botón al pasar sobre él */
        color: black; /* Cambia el color del texto del botón al pasar sobre él */
    }

    .leftContainer{
        margin-bottom: 10px;
        text-align: center;
        
    }
    .rightContainer{
        display: none;
    }
}

