.FAQBody {
  padding-top: 4%; 
  padding-bottom: 4%; 
  background-color: #f9f9f9;
}


.FAQMainBlock {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
}

.faq-list {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.faq-item {
  border-bottom: 1px solid #ccc;
}

.faq-question {
  background-color: #f0f0f0;
  padding: 18px; /* Se aumentó el padding */
  font-size: 18px;
  cursor: pointer;
}

.faq-question.active {
  background-color: #6044f4;
  color: white;
}

.faq-question:hover {
  background-color: #6044f4;
  color: white;
}

.faq-answer {
  background-color: #ffbc3c;
  font-size: 18px;
  color: black;
  padding: 18px; /* Se aumentó el padding */
  display: none;
}

.faq-answer.show {
  display: block;
}
