body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.serviceMain{
    background-color: white;
    padding-top: 2%;

}

.servicesBlockMainContainer {
    
    padding-bottom: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    gap: 20px;
}

.servicesBlockMainItem {
    background-color: #f9f9f9;
    width: 400px;
    margin: 20px;
    padding: 20px;
    color: black;
    border-radius: 10px;
    text-align: center;
    transition-duration: 0.4s; 
}
.servicesBlockMainItem:hover{
    background-color: #6144f4;
    color: white;

}


.servicesBlockMainItem h2 {
    margin-top: 0;
}

.servicesBlockMainItem p {
    margin-bottom: 10px;
}

.servicesBlockMainItem img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.smallImage {
    width: 50px; /* Ancho de la imagen */
    height: auto; /* Altura automática para mantener la proporción */
}

