.fistBlockMainContainer {
    background-color: #6044f4;
    border: 2px;
    width: 100%;
    margin: 0 auto; /* Esto centra el contenedor */
    display: flex; /* Utilizamos flexbox para centrar el contenido verticalmente */
    justify-content: center; /* Centra el contenido horizontalmente */
    padding-bottom: 25px;
}

.fistBlockMainContainer a{
    text-decoration: none;
    color: black;
}


.firstBlockTextContainer {
    padding-left: 50px;
    text-align: center; /* Centra el contenido del texto horizontalmente */
    width: 50%;
    display: flex; /* Utilizamos flexbox */
    flex-direction: column; /* Alinear el contenido en columna */
    justify-content: center; /* Centrar verticalmente el contenido */
    align-items: center; /* Centrar horizontalmente el contenido */
}

.firstBlockTextContainer h1 {
    color: white; /* Texto blanco */
    font-size: 2.5rem; /* Tamaño del texto */
    font-weight: bold; /* Texto en negrita */
    text-transform: uppercase; /* Transforma el texto a mayúsculas */
    margin-bottom: 20px; /* Espacio inferior */
}

.firstBlockTextContainer p {
    font-size: 22px;
    text-align: left;
    color: white; /* Texto blanco */
    
    line-height: 1.6; /* Altura de línea */
    margin-bottom: 20px; /* Espacio inferior */
}

.buttonFistBlockMainContainer {
    background-color: #ffbc3c; /* Color de fondo */
    width: 150px; /* Reducir el ancho del botón */
    border: none;
    color: black; /* Color del texto */
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: flex; /* Utilizamos flexbox */
    justify-content: center; /* Centramos horizontalmente el botón */
    align-items: center; /* Centramos verticalmente el botón */
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px; /* Añade bordes redondeados */
    transition-duration: 0.4s; /* Efecto de transición al pasar el ratón */
}

.buttonFistBlockMainContainer:hover {
    background-color: white; /* Cambia el color de fondo al pasar el ratón */
    color: black; /* Cambia el color del texto al pasar el ratón */
}

@media screen and (max-width: 768px) {
    .fistBlockMainContainer {
        flex-direction: column; /* Apilar elementos en pantallas más pequeñas */
        align-items: center; /* Centrar elementos horizontalmente */
    }

    .firstBlockTextContainer {
        width: 90%; /* Ancho completo en pantallas más pequeñas */
        padding-left: 0; /* Eliminar el relleno izquierdo */
    }

    .buttonFistBlockMainContainer {
        width: 100%; /* Ancho completo en pantallas más pequeñas */
    }
}
