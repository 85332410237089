.proposalBlockMainContainer {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-wrap: wrap;
  background-color: #6044f4; /* Mantengo el color original */
  transition: background-color 0.3s ease;
  color: white;
}

.proposalBlockMainContainer:hover {
  background-color: #ffbc3c; /* Mantengo el color original */
  color: black;
}

.proposalBlockTextContainer {
  flex: 1;
  margin-right: 20px; /* Espacio entre bloques */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente el contenido */
}

.proposalBlockTextContainer p{
  font-size: 21px;
}

.proposalBlockText {
  max-width: 100%; /* Asegura que el texto no sobrepase el ancho del contenedor */
}

@media screen and (max-width: 768px) {
  .proposalBlockMainContainer {
    flex-direction: column;
    align-items: center; /* Centra horizontalmente en pantallas pequeñas */
    padding: 5% 5%; /* Reducir el relleno */
  }

  .proposalBlockTextContainer {
    width: 100%; /* El texto ocupa el 100% del ancho en dispositivos más pequeños */
    margin-right: 0; /* Elimina el margen entre bloques */
    margin-bottom: 20px; /* Espacio entre bloques en la pila */
    text-align: center; /* Centra horizontalmente el texto */
  }
}
