body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}
#productsContainer{
    padding-top: 5%;
    padding-bottom: 5%;
}
.productBlockMainContainer {

    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    gap: 20px;
}

.productBlockMainItem {
    width: 300px;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    text-align: center;
    transition-duration: 0.4s; 
}

.productBlockMainItem:hover{
    background-color: #6144f4;
    color: white;

}

.productBlockMainItem h2 {
    margin-top: 0;
}

.productBlockMainItem p {
    margin-bottom: 10px;
    text-align: left;
}

.productBlockMainItem img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.smallImage {
    width: 50px; /* Ancho de la imagen */
    height: auto; /* Altura automática para mantener la proporción */
}

@media (max-width: 768px) {
    .productBlockMainItem {
        width: 400px;/* Ajustado el margen superior e inferior */
    }
}