#chooseUsBody{
    padding-top: 5%;
}

/* Estilos base para los contenedores principales */
.chooseUsBlockMainContainer {
    padding-bottom: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    margin: 0 auto;
    gap: 20px;
}

/* Estilos para los contenedores de texto */


.chooseUsBlockTextContainer {
    width: 400px;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    text-align: center;
    transition-duration: 0.4s; 
    
}

.chooseUsBlockTextContainer p{text-decoration: none;
    text-align: left;}

.chooseUsBlockTextContainer:hover {
    background-color: #ffbc3c;
    
}

/* Estilos para las imágenes */
.chooseUsImages {
    max-width: 10%; /* Cambiado a 100% para que las imágenes sean responsivas */
    height: auto;
    border-radius: 5px;
}

/* Media query para pantallas más pequeñas */
@media (max-width: 768px) {
    .chooseUsBlockTextContainer {
        width: 400px; /* Ancho completo en pantallas pequeñas */
        /* Ajustado el margen superior e inferior */
    }
}
