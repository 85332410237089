/* Estilos para dispositivos móviles */
/* Estilos para dispositivos móviles */

@media (max-width: 340px){
    .AboutUsBlockMainContainer {
        padding-top: 4%;
        padding-bottom: 4%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;

    }

    .AboutUsLeftContainer, .AboutUsRightContainer {
        width: 90%;
        margin: 20px 0;
        
    }
    


    #imageAU {
        width: 150px;
        height: 150px;
        border-radius: 15px;
        position: relative;
        z-index: 2;
    }
}
@media (min-width: 341px) AND (max-width: 499px) {
    .AboutUsBlockMainContainer {
        padding-top: 4%;
        padding-bottom: 4%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;

    }

    .AboutUsLeftContainer, .AboutUsRightContainer {
        width: 90%;
        margin: 20px 0;
        
    }
    


    #imageAU {
        width: 150px;
        height: 150px;
        border-radius: 15px;
        position: relative;
        z-index: 2;
    }

    .purple-rectangle {
        width: 150px;
        height: 150px;
        background-color: #6044f4;
        border-radius: 15px;
        position: absolute;
        top: 50%; /* Centrar verticalmente */
        left: 50%; /* Centrar horizontalmente */
        transform: translate(-70%, -170%); /* Centrar completamente */
        z-index: 1;
    }
}

@media (min-width: 500px) AND (max-width: 790px) {
    .AboutUsBlockMainContainer {
        padding-top: 4%;
        padding-bottom: 4%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;

    }

    .AboutUsLeftContainer, .AboutUsRightContainer {
        width: 90%;
        margin: 20px 0;
        
    }
    


    #imageAU {
        width: 150px;
        height: 150px;
        border-radius: 15px;
        position: relative;
        z-index: 2;
    }

    .purple-rectangle {
        width: 150px;
        height: 150px;
        background-color: #6044f4;
        border-radius: 15px;
        position: absolute;
        top: 50%; /* Centrar verticalmente */
        left: 50%; /* Centrar horizontalmente */
        transform: translate(-70%, -150%); /* Centrar completamente */
        z-index: 1;
    }
}

/* Estilos para dispositivos de escritorio */
@media (min-width: 791px) and (max-width: 1200px) {
    .AboutUsBlockMainContainer {

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 4%;
        padding-bottom: 4%;
        max-width: 90%; /* Delimita el ancho máximo del bloque de contenido */
        margin: 0 auto; /* Centra el bloque de contenido */
    }

    .AboutUsLeftContainer {
        
        width: 60%;
        margin-right: 20px;
    }

    .AboutUsRightContainer {
        width: 40%;
        margin-left: 20px;
        
    }

    #imageAU {
        width: 300px;
        height: auto;
        border-radius: 15px;
        position: relative;
        z-index: 2;
    }

    .purple-rectangle {
        width: 300px;
        height: 300px;
        background-color: #6044f4;
        border-radius: 15px;
        position: absolute;
        top: 50%; /* Centrar verticalmente */
        left: 50%; /* Centrar horizontalmente */
        transform: translate(-125%, -45%); /* Centrar completamente */
        z-index: 1;
        transition: background-color 0.3s ease;
    }

    #imageAU:hover ~ .purple-rectangle {
        background-color: #ffbc3c; /* Cambiar el color del rectángulo al hacer hover sobre la imagen */
    }
}

@media (min-width: 1201px) AND (max-width: 1600px) {
    .AboutUsBlockMainContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 4%;
        padding-bottom: 4%;
        max-width: 90%; /* Delimita el ancho máximo del bloque de contenido */
        margin: 0 auto; /* Centra el bloque de contenido */
    }

    .AboutUsLeftContainer {
        
        width: 60%;
        margin-right: 20px;
    }

    .AboutUsRightContainer {
        width: 40%;
        margin-left: 20px;
        
    }

    #imageAU {
        width: 500px;
        height: auto;
        border-radius: 15px;
        position: relative;
        z-index: 2;
    }

    .purple-rectangle {
        width: 500px;
        height: 500px;
        background-color: #6044f4;
        border-radius: 15px;
        position: absolute;
        top: 50%; /* Centrar verticalmente */
        left: 50%; /* Centrar horizontalmente */
        transform: translate(-110%, -45%); /* Centrar completamente */
        z-index: 1;
        transition: background-color 0.3s ease;
    }

    #imageAU:hover ~ .purple-rectangle {
        background-color: #ffbc3c; /* Cambiar el color del rectángulo al hacer hover sobre la imagen */
    }
}

@media (min-width: 1601px) {
    .AboutUsBlockMainContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 4%;
        padding-bottom: 4%;
        max-width: 90%; /* Delimita el ancho máximo del bloque de contenido */
        margin: 0 auto; /* Centra el bloque de contenido */
    }

    .AboutUsLeftContainer {
        
        width: 60%;
        margin-right: 20px;
    }

    .AboutUsRightContainer {
        width: 40%;
        margin-left: 20px;
        
    }

    #imageAU {
        width: 500px;
        height: auto;
        border-radius: 15px;
        position: relative;
        z-index: 2;
    }
}

