.contactPageMainContainer {
    /*border: solid 2px black;*/
    display: flex;
    justify-content: center; /* Centers the containers horizontally */
    align-items: center; /* Centers the containers vertically */
    flex-wrap: wrap; /* Ensures responsiveness by wrapping containers if necessary */
    gap: 1%; /* Adds spacing between containers */
    padding: 10px; /* Adds some padding for aesthetics */
    box-sizing: border-box; /* Ensures padding is included in the width/height */
}

.contactPageLeftContainer,
.contactPageRightContainer {
    flex: 1 1 49%; /* Makes each container flexible, taking up 49% width */
    max-width: 49%; /* Ensures containers don't grow beyond 49% */
    /*border: solid 4px blue;*/
    box-sizing: border-box; /* Includes borders in the width/height */
    height: auto;
}

.contactPageRightContainer {
   /* border-color: red; */ /* Red border for the right container */
}

/* Optional: Add responsiveness for smaller screens */
@media (max-width: 768px) {
    .contactPageLeftContainer,
    .contactPageRightContainer {
        flex: 1 1 100%; /* Each container will take full width on smaller screens */
        max-width: 100%;
    }
}