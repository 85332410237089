.mainContainer1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #6044f4;
    /* Color de fondo */
    color: #fff;
    /* Color del texto */
}

.leftContainer1 h1 {
    font-weight: bold;
    margin: 0;
    font-size: 22px;
    
}

@media screen and (max-width: 768px) {
    .mainContainer {
        flex-direction: column;
        padding: 10px;
    }

    .leftContainer,
    .rightContainer {
        margin-bottom: 10px;
        text-align: center;
    }
}

.instagram-icon {
    position: relative;
}

.instagram-icon a {
    display: inline-block;
    color: white;
}

.instagram-icon a::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    /* Tamaño del círculo */
    height: 40px;
    /* Tamaño del círculo */
    background-color: rgba(0, 0, 0, 0.2);
    /* Color del círculo */
    border-radius: 50%;
    /* Hace que el círculo tenga forma redonda */
    opacity: 0;
    /* Inicialmente invisible */
    transition: opacity 0.3s ease;
    /* Efecto de transición */
}

.instagram-icon a:hover::before {
    opacity: 1;
    /* Hace visible el círculo al pasar el cursor sobre el enlace */
}

.instagram-icon svg {
    vertical-align: middle;
}