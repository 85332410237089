/* Estilos para el contenedor principal del banner */
.scheduleBannerBlockMainContainer {
    background-color: #ffbc3c;/* Color de fondo del banner */
    color: black; /* Color del texto */
    padding: 20px; /* Espaciado interno */
    text-align: center; /* Alineación del texto al centro */
    display: inline-block; /* Para que el contenedor abarque solo el texto */
    width: 100%; /* Ocupar todo el ancho de la página */
    max-width: 100%; /* Establecer un ancho máximo */
    margin: 0 auto; /* Centrar el contenedor en la página */
    box-sizing: border-box; /* Incluir el padding en el ancho */
}

/* Estilos para el texto dentro del banner */
.scheduleBannerBlockMainContainer .scheduleBannerText {
    font-size: 18px;
    transition: transform 0.3s ease-in-out; /* Agrega transición suave solo para la transformación */
    display: inline-block; /* Para que el contenedor de texto se ajuste al texto */
    font-weight: bolder;
}

.scheduleBannerBlockMainContainer .scheduleBannerText p {
    font-size: 18px;
    transition: transform 0.3s ease-in-out; /* Agrega transición suave solo para la transformación */
    display: inline-block; /* Para que el contenedor de texto se ajuste al texto */
    font-weight: bolder;
}

/* Estilos para el texto cuando se pasa el ratón por encima */
.scheduleBannerBlockMainContainer:hover .scheduleBannerText {
    transform: scale(2); /* Hace más grande solo el texto al pasar el ratón por encima del contenedor principal */
}

/* Estilos para el enlace dentro del banner */
.scheduleBannerBlockMainContainer .scheduleBannerText a {
    color: black; /* Color del enlace */
    text-decoration: none; /* Eliminar el subrayado del enlace */
    font-weight: bold; /* Peso de la fuente */
}

/* Estilos para el enlace cuando se pasa el ratón por encima */
.scheduleBannerBlockMainContainer .scheduleBannerText a:hover {
    text-decoration: underline; /* Subrayar el enlace al pasar el ratón por encima */
}

.scheduleBannerBlockMainContainer p{
    text-align: center;
}