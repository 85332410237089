.formMainContainer {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }


  
  h2 {
    text-align: center;
  }
  
  .form-group {
    display: flex;
    justify-content: space-between; /* Aligns inputs horizontally with space between */
    align-items: center; /* Vertically centers inputs and labels */
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 15px; /* Adds spacing between elements */
    margin-bottom: 20px;
}

label {
    flex: 1 1 20%; /* Labels take up 20% of the row width */
    font-weight: bold;
    font-size: 1rem;
    margin-right: 10px; /* Space between label and input */
}

input[type="text"] {
    flex: 1 1 80%; /* Inputs take up 40% of the row width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"]:focus {
    border-color: #007bff; /* Changes border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle glow effect */
    outline: none;
}

/* Responsive: Stacks label and input on smaller screens */
@media (max-width: 768px) {
  .formMainContainer{
    width: 300px;
    max-width: 400px;
  }
    .form-group {
        flex-direction: column;
        align-items: stretch; /* Makes inputs fill full width */
    }

    label {
        flex: 1 1 auto; /* Labels adjust to content size */
        margin-bottom: 5px; /* Adds spacing below the label */
    }

    input[type="text"] {
        flex: 1 1 auto; /* Inputs adjust to full width */
    }
}
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #ffbc3c;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  button:hover {
    background-color: #6044f4;
    color: white;
    font-weight: bold;
  }
  
  button:focus {
    outline: none;
  }
  